.customPopup {
  align-items: flex-start !important;

  .customPopupContainer {
    max-width: 800px;
    top: 30px!important;

    .main-popup-wrapper {
      height: 600px;
      overflow-x: hidden;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }

    .main-popup__text {
      text-align: justify;
      padding-right: 20px;

      .user-guide-step {
        margin-top: 15px;

        .image {
          text-align: center;
          padding: 20px 0;

          & img {
            width: 100%;
          }
        }

        .list-none {
          list-style: none;
          padding-left: 0;
        }
      }
    }
  }
}