.somethingWentWrongContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column; }
.contentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
        font-weight: 700;
        font-size: 36px;
        line-height: 34.45px;
        margin-bottom: 0; } }

.cm-picture-error {
    width: 100% !important;
    height: 100% !important;
    margin: 0 auto;
    padding-right: 0 !important; }
