.react-datepicker {
  border-radius: 8px !important;
  border: 1px solid rgba(168, 182, 194, 0.7) !important;

  .react-datepicker__triangle {
    transform: translate3d(40px, 0, 0) !important;
    background-color: #ffffff;

    &::before {
      border-bottom-color: rgba(168, 182, 194, 0.7) !important;
    }

    &:after {
      border-bottom-color: #ffffff !important;
    }
  }

  .react-datepicker__month-container {
    border-radius: 6px !important;
    background: #ffffff;
    font-family: 'HCo Gotham', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, sans-serif !important;

    & .react-datepicker__month {
      margin: 8px 16px;
    }

    & .react-datepicker__header {
      background-color: #ffffff;
      border-top-left-radius: 0;
      padding: 0;
      border-bottom: none;
    }

    & .months-button {
      background: transparent;
      outline: none;
      border: none;
      padding: 0;

      &:first-child {
        margin-right: 10px;
      }

      & svg {
        vertical-align: bottom;
      }
    }
  }
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  margin: 2px !important;
  height: 28px !important;
  width: 28px !important;
  color: #24282C !important;
  font-size: 12px !important;
  line-height: 28px !important;

  &:hover {
    border-radius: 50% !important;
  }
}

.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
  background: #068EEF !important;
  border-radius: 50% !important;
  height: 28px !important;
  width: 28px !important;
  line-height: 28px !important;
  margin: 2px !important;
  color: #ffffff !important;
}

.react-datepicker-wrapper:first-child {
  margin-right: 8px;
}

.react-datepicker__day--disabled {
  color: #cccccc !important;
}

.react-datepicker-popper {
  z-index: 30;
}

.iconable-block__icon {
  margin-right: 24px;
}

.react-datepicker_button_clear {
  & .react-datepicker_button_clear_wrapper {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 12px 0;
  }
}

@media screen and (min-width: 768px) {
  .cta-block .cta-block__cta .btn {
    width: auto;
    min-width: auto;
  }
}