.a-card {
    &--normal {
        max-width: 100%; } }

.cm-info-block {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: none;
    &--bg-info {
        background-color: #CDE8FC !important; }
    &__content {
        display: flex;
        flex-direction: row;
        &__buttons {
            display: flex;
            justify-content: center;
            align-items: center; }
        &__button {
            margin-right: 8px; }
        &__title {
            margin-left: 0 !important; } } }
