$xl: 28px;
$l: 26px;
$m: 18px;
$s: 12px;
$firstColor: #068eef;
$lastColor: #1f2328;

.route {
    position: relative;
    margin-left: $s;
    padding-left: $l;
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 15px;
        right: auto;
        bottom: 15px;
        left: 0;
        border-left: 3px dashed $firstColor; } }

.origin, .destination {
    line-height: $xl;
    position: relative;
    &:after {
        content: '';
        display: block;
        width: $s;
        height: $s;
        position: absolute;
        left: -31px; // offsetting by 1px
        top: 9px;
        border: 2px solid $firstColor;
        background-color: $lastColor;
        border-radius: 50%;
        z-index: 10; } }

.destination:after {
    background-color: $firstColor; }

.shipment-id, .cargo-ready-date {
    line-height: $l;
    white-space: nowrap; }
