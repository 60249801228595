.bubble {
    &__text, &__title {
        white-space: break-spaces; } }

.cm-canonical-status {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    position: relative; }

.cm-tooltip-absolute {
    min-width: 250px;
    top: 50%;
    transform: translate(100%, -50%);
    right: -8px; }
